import { useSplits } from '@mentimeter/splitio';

type OnboardingRoute = '/app/onboarding' | '/app/new?onboarding';

export const useOnboardingPageRouting = (): {
  isReady: boolean;
  onboardingPath: OnboardingRoute;
} => {
  const { isReady, USE_Onboarding_New_Page } = useSplits([
    'USE_Onboarding_New_Page',
  ]);

  const onboardingPath =
    USE_Onboarding_New_Page === 'on'
      ? '/app/onboarding'
      : '/app/new?onboarding';

  return {
    isReady,
    onboardingPath,
  };
};
