import { Box, Button, Text } from '@mentimeter/ragnar-ui';
import { useTranslation } from '@mentimeter/i18n';
import React from 'react';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';

export const WorkspaceHint = ({
  companyName,
  joinUrl,
  samlLoginUrl,
  teamPromotionClicked,
  showWorkspaceHint,
  handleTeamPromotionClick,
}: {
  companyName: string;
  joinUrl: string;
  samlLoginUrl: string;
  teamPromotionClicked: boolean;
  showWorkspaceHint: boolean;
  handleTeamPromotionClick: () => void;
}) => {
  const { t } = useTranslation('authPackage');

  if (!showWorkspaceHint) {
    return null;
  }

  return (
    <Box width={1} alignItems="center" p={3} mb={3} bg="text" borderRadius={1}>
      <Text fontSize={1} fontWeight="semiBold" color="bg">
        <Box>
          <Text color="bg" mb={1}>
            {t('workspace_hint_title', { companyName })}
          </Text>
          <Text fontSize="inherit" fontWeight="light" color="bg" mb={3}>
            {t('workspace_hint_body')}
          </Text>
        </Box>
        <Button
          href={joinUrl || samlLoginUrl}
          variant="positive"
          onClick={handleTeamPromotionClick}
        >
          {teamPromotionClicked ? (
            <LoaderIcon />
          ) : (
            t('workspace_hint_button_text')
          )}
        </Button>
      </Text>
    </Box>
  );
};
