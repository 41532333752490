import * as React from 'react';
import { P, Heading, Box, Button } from '@mentimeter/ragnar-ui';
import { useTranslation } from '@mentimeter/i18n';

interface PropsT {
  showSignup: boolean;
  setAuthMode: React.MouseEventHandler<HTMLButtonElement>;
}
const AuthModalHeader = ({ showSignup, setAuthMode }: PropsT) => {
  const { t } = useTranslation('authPackage');
  const headingText = showSignup ? t('create_free_account') : t('login');
  const switchText = showSignup
    ? t('already_have_account')
    : t('no_account_yet');
  const buttonText = showSignup ? t('login') : t('form_signup_button');
  return (
    <>
      <Box width={1} alignItems="center">
        <Heading textAlign="center">{headingText}</Heading>
      </Box>
      <Box flexDirection="row" alignItems="baseline">
        <P mt={1} mb={4}>
          {switchText}{' '}
        </P>
        <Button variant="subtle" href="#" onClick={setAuthMode} ml={1}>
          {buttonText}.
        </Button>
      </Box>
    </>
  );
};

export default AuthModalHeader;
