/**
 * THIS FILE IS DEPRECATED
 * Do not add any new icons to this file.
 * Use the new functional icon system instead. See functional-icons/README.md for more information.
 */

import * as React from 'react';
import type { ColorsEnum } from '@mentimeter/ragnar-dsc';
import { designSystemConfig, isThemeColor } from '@mentimeter/ragnar-dsc';
import type { ThemeProps } from '@mentimeter/ragnar-react';
import { withTheme } from '@mentimeter/ragnar-react';
import { Svg } from './shared-components';

export type Size = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export interface IconT {
  size?: Size | number | undefined | string;
  color?: ColorsEnum | undefined;
  bg?: ColorsEnum | undefined;
  rounded?: boolean | undefined;
  'aria-hidden'?: boolean | undefined;
  'aria-label'?: string | undefined;
}

export interface DefaultIconT extends IconT, ThemeProps {
  children: React.ReactNode;
  viewBox?: string;
}

function DefaultIcon({
  size = 2,
  color = 'text',
  bg = 'none',
  rounded = false,
  children,
  theme = designSystemConfig,
  viewBox = '0 0 24 24',
  'aria-hidden': ariaHidden,
  'aria-label': ariaLabel,
}: DefaultIconT) {
  return (
    <Svg
      viewBox={viewBox}
      height={typeof size === 'number' ? theme.fontSizes[size] || size : size}
      width={typeof size === 'number' ? theme.fontSizes[size] || size : size}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
      style={{
        backgroundColor: isThemeColor(theme, bg) ? theme.colors[bg] : bg,
        borderRadius: rounded ? '50%' : 'none',
      }}
    >
      <g color={isThemeColor(theme, color) ? theme.colors[color] : color}>
        {children}
      </g>
    </Svg>
  );
}

export const Icon = withTheme<DefaultIconT>(DefaultIcon);
