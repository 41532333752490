import * as React from 'react';
import { useTranslation, withMentimeterI18nProvider } from '@mentimeter/i18n';
import { getVisitorToken } from '@mentimeter/splitio';
import { P, Box, Button } from '@mentimeter/ragnar-ui';
import { getUtmValues } from '@mentimeter/utm';
import type AuthPackageT from '../../AuthPackage';
import { applyQueryToPath } from '../../utils/queryString';
import { useAuthHandlers } from '../../useAuthHandlers';
import { SocialAuthWrapper } from './SocialAuthWrapper';
import LogInForm from './LogInForm';
import { Divider } from './Divider';
import { AuthErrorMessage } from './AuthErrorMessage';

export interface Props {
  authPackage: AuthPackageT;
  initialEmail?: string | undefined;
  initialPassword?: string | undefined;
  referral: string;
  continueUrl?: string | undefined;
  useSocialRedirect?: boolean | undefined;
  emailChangeDisabled?: boolean | undefined;
  maxWidth?: string;
}

function LogInUI({
  referral,
  authPackage,
  initialEmail,
  initialPassword,
  continueUrl = '/app',
  useSocialRedirect,
  emailChangeDisabled = false,
  maxWidth,
}: Props) {
  const {
    components: { Login: LogInAuth },
  } = authPackage;
  const { t, i18n } = useTranslation('authPackage');
  const isSpanish = i18n.language === 'es-ES';
  const isEnglish = i18n.language === 'en-GB' || i18n.language === 'en-US';
  const resetPwdHref = applyQueryToPath('/auth/reset_passwords');
  const { utmFirst, utmLast } = getUtmValues();
  const visitorToken = getVisitorToken();
  const {
    onError,
    error,
    onSocialAuthSuccess,
    onLoginSuccess,
    isLoading,
    setLoading,
    disabled,
  } = useAuthHandlers({ continueUrl, referral });

  const emailVerificationRedirectCopy = 'Log in to verify your email address';
  const showEmailVerificationHint =
    isEnglish && continueUrl.includes('/app/verify/link?token');

  return (
    <Box
      width="100%"
      alignItems="stretch"
      maxWidth={maxWidth ?? '360px'}
      mx="auto"
      bg="bg"
      borderRadius={1}
      p={4}
    >
      <P textAlign="center" mb={3} fontWeight="semiBold">
        {showEmailVerificationHint
          ? emailVerificationRedirectCopy
          : t('log_in_to_menti_account')}
      </P>
      <SocialAuthWrapper
        visitorToken={visitorToken}
        onError={onError}
        setLoading={setLoading}
        onSuccess={onSocialAuthSuccess}
        utmFirst={utmFirst}
        utmLast={utmLast}
        disabled={disabled}
        referral={referral}
        isSignup={false}
        useRedirect={Boolean(useSocialRedirect)}
        authPackage={authPackage}
      />
      <Divider />
      <LogInAuth
        visitorToken={visitorToken}
        onSuccess={onLoginSuccess}
        onError={onError}
        setLoading={setLoading}
        render={({ handleSubmit }) => (
          <LogInForm
            onSubmit={handleSubmit}
            isLoading={isLoading}
            disabled={disabled}
            initialEmail={initialEmail}
            initialPassword={initialPassword}
            emailChangeDisabled={emailChangeDisabled}
          />
        )}
      />
      {error ? (
        <AuthErrorMessage error={error} continueUrl={continueUrl} mb={2} />
      ) : null}
      <Box
        width="100%"
        flexDirection={isSpanish ? ['column'] : ['column', 'row']}
        alignItems="center"
      >
        <Button
          variant="subtle"
          href={resetPwdHref}
          mr={[0, 1]}
          mb={[2, 0]}
          mt={[1, 2]}
          width={1}
        >
          {t('forgot_password')}
        </Button>
        <Button
          variant="subtle"
          href={`/auth/saml${continueUrl ? `?continue=${continueUrl}` : ''}`}
          ml={[0, 1]}
          mt={[0, 2]}
          width={1}
        >
          {t('login_sso')}
        </Button>
      </Box>
    </Box>
  );
}

export default withMentimeterI18nProvider(LogInUI);
