import * as React from 'react';
import { useTranslation, Trans } from '@mentimeter/i18n';
import { Meta, Link } from '@mentimeter/ragnar-ui';

export const Terms = () => {
  const { t } = useTranslation('authPackage');
  return (
    <Meta as="p" textAlign="center">
      <Trans t={t} i18nKey="form_disclaimer">
        By signing up you accept our
        <Link
          color="inherit"
          href="/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms of use
        </Link>
        and
        <Link
          color="inherit"
          href="/policies"
          target="_blank"
          rel="noopener noreferrer"
        >
          policies
        </Link>
        .
      </Trans>
    </Meta>
  );
};

export default Terms;
