import * as React from 'react';
import { useTranslation } from '@mentimeter/i18n';
import { Meta, Box } from '@mentimeter/ragnar-ui';

export const Divider = () => {
  const { t } = useTranslation('authPackage');

  return (
    <Box my={3} alignItems="center">
      <Meta as="p" textAlign="center">
        {t('or_email')}
      </Meta>
    </Box>
  );
};
